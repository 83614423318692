<template>
    <div>
        <ul class="nav nav-tabs"
            id="myTab"
            role="tablist">
            <li v-for="(year, index) in this.getYears"
                class="nav-item"
                role="presentation">
                <button class="nav-link"
                    :class="index == 0 ? 'active': ''"
                    :id="getYearButtonId(year)"
                    data-toggle="tab"
                    :data-target="getTarget(year)"
                    type="button"
                    role="tab"
                    :aria-controls="year"
                    :aria-selected="index == 0 ? true : false">{{year}}</button>
            </li>

        </ul>
        <div class="tab-content"
            id="priceTabContent">
            <div v-for="(year, index) in this.getYears"
                class="tab-pane fade"
                :class="index == 0 ? 'show active' : ''"
                :id="getTabId(year)"
                role="tabpanel"
                :aria-labelledby="getTabAreaLabel(year)">
                <table class="table pricelist table-hover">
                    <thead>
                        <tr class="subrow">
                            <th>Saison</th>
                            <th>Zeitraum</th>
                            <th class="text-right">Preis p. Nacht</th>
                        </tr>
                    </thead>
                    <tbody v-for="sg in priceArray">
                        <tr v-for="price in sg.lines"
                            v-if="isDateInRange(price.fromdate, year)"
                            class="subrow">
                            <td class="subinfo align-top">
                                <span>{{price.season}}</span>
                                <br/>
                            </td>
                            <td><span v-if="price.fromdate">{{getFormattedDate(price.fromdate)}} - {{getFormattedDate(price.tilldate)}}</span>
                                <!-- <br /><span>zzgl. einmaliger Nebenkosten</span>-->
                            </td>
                            <td class="text-right align-top">
                                <span>ab {{getCalculatedPrice(price.value)}}</span>
                                <!--<br/><span>{{getCalculatedMandatoryPrice()}}
                                <price-help></price-help></span>-->
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>
</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'price-tab',
    	props: {
    		pricelist: String
    	},
    	data: function() {
    		return {
    			priceArray: {},
    			unitId: Number,
    			priceBookingFee: Number,
    			priceFinalCleaning: Number
    		};
    	},
    	mounted: function() {
    		var pricelist = JSON.parse(this.pricelist);
    		this.priceArray = pricelist;

    		var years = [];

    		var i, j;
    		for (i = 0; i < pricelist.length; i++) {
    			if (pricelist[i].service.type && pricelist[i].service.type === 'FINALCLEANING') {
    				//console.log('Type::', pricelist[i].service.type, 'Price::', pricelist[i].price);
    				this.priceFinalCleaning = pricelist[i].price;
    			}
    			if (pricelist[i].service.type && pricelist[i].service.type === 'BOOKING_FEE') {
    				//console.log('Type::', pricelist[i].service.type, 'Price::', pricelist[i].price);
    				this.priceBookingFee = pricelist[i].price;
    			}
    			for (j = 0; j < pricelist[i].lines.length; j++) {
    				//console.log('inside loop date::' + pricelist[i].lines[j].fromdate);
    				if (pricelist[i].lines[j].fromdate) {
    					var year = pricelist[i].lines[j].fromdate.substring(0, 4);
    					//console.log('year is::' + year);
    					if (years.indexOf(year) < 0) {
    						years.push(year);
    					}
    				}
    			}
    		}
    	},
    	methods: {
    		getYearButtonId: function(year) {
    			return 't' + year + '-tab';
    		},
    		getTarget: function(year) {
    			return "#t" + year;
    		},
    		getTabId: function(year) {
    			return "t" + year;
    		},
    		getTabAreaLabel: function(year) {
    			return "t" + year + "-tab";
    		},
    		getFormattedDate: function(date) {
    			return DateEx.formatDate(date, 'dd.MM.yy', 'de');
    		},
    		getCalculatedPrice: function(price) {
    			var rentPrice = 0;
    			var calcPrice = 0;
    			if (price > 0) {
    				rentPrice = price;
    				calcPrice = Number((rentPrice) / 100).toLocaleString("de-DE", {
    					minimumFractionDigits: 2
    				}) + " €";
    			}
    			return calcPrice;
    		},
    		getCalculatedMandatoryPrice: function() {
    			var rentPrice = 0;
    			var calcPrice = 0;
    			calcPrice = Number((this.priceBookingFee + this.priceFinalCleaning) / 100).toLocaleString("de-DE", {
    				minimumFractionDigits: 2
    			}) + " €";

    			return calcPrice;
    		},
    		isDateInRange: function(fromdate, year) {
    			if (!fromdate) {
    				return true;
    			}
    			var fromYear = parseInt(fromdate.substring(0, 4));
    			if (fromYear == year) {
    				return true;
    			}
    			return false;
    		}

    	},
    	computed: {
    		getYears: function() {
    			var pricelist = JSON.parse(this.pricelist);
    			var years = [];

    			var i, j;
    			for (i = 0; i < pricelist.length; i++) {
    				for (j = 0; j < pricelist[i].lines.length; j++) {
    					if (pricelist[i].lines[j].fromdate) {
    						var year = pricelist[i].lines[j].fromdate.substring(0, 4);
    						if (years.indexOf(year) < 0) {
    							years.push(year);
    						}
    					}
    				}
    			}
    			return years;
    		}
    	}
    };
</script>