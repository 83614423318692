import { render, staticRenderFns } from "./GuestBox.vue?vue&type=template&id=ced738d4&scoped=true&"
import script from "./GuestBox.vue?vue&type=script&lang=js&"
export * from "./GuestBox.vue?vue&type=script&lang=js&"
import style0 from "./GuestBox.vue?vue&type=style&index=0&id=ced738d4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ced738d4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ced738d4')) {
      api.createRecord('ced738d4', component.options)
    } else {
      api.reload('ced738d4', component.options)
    }
    module.hot.accept("./GuestBox.vue?vue&type=template&id=ced738d4&scoped=true&", function () {
      api.rerender('ced738d4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-8R2lBWU3AMvQ0/JS/comp/GuestBox.vue"
export default component.exports